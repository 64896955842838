import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Input,
    Select,
    Upload,
    message,
    Form,
    Row,
    Col,
    Card,
    Space,
    Typography,
    Switch,
} from "antd";
import { CloseCircleOutlined, MinusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Template,
    TemplateButton,
    TemplateCategory,
    TemplateType,
} from "frontend-lib/DomainTypes/Template";
import { Language } from "frontend-lib/DomainTypes/Languages";
import { RcFile } from "antd/lib/upload";
import { CreateTemplateFn } from "frontend-lib/Hooks/useTemplates";
import useCustomerFields from "frontend-lib/Hooks/useCustomerFields";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import SelectOnce from "./SelectOnce";
import { useHistory } from "react-router-dom";
import api from "requests/api";
import WhatsappMessagePreview from "frontend-lib/Components/WhatsappMessagePreview/WhatsappMessagePreview";
import CustomEmojiPicker, { Emoji } from "./CustomEmojiPicker";
import "./NewTemplateForm.scss";
import FormItem from "antd/lib/form/FormItem";
import { Logger } from "frontend-lib/Log";
import { AppContext } from "components/AppContext";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

interface NewTemplateFormProps {
    templates: Template[];
    reloadCb: () => void;
    createTemplateCb: CreateTemplateFn;
}

interface FieldData {
    name: string[] | any;
    value?: string | any;
    [key: string]: any;
}

const templateBodyName = "templateBody";
const templateExampleName = "templateBodyExample";

const quickReplyButton = "quickReplyButton";

const urlButtonName = "urlButton";
const urlButtonExampleName = "urlButtonExample";
const urlButtonText = "urlButtonText";

const phoneNumber = "phoneNumber";
const phoneNumberButtonText = "phoneNumberButtonText";

const addVariableListName = "addVariableListName";
const addUnsubscribeTextCheckbox = "addUnsubscribeTextCheckbox";

type buttonsType = "QUICK_REPLY" | "PHONE" | "URL";

const acceptedFileTypes = ["image/png", "image/jpg", ".pdf"].join(",");

const templateNumericVariables = Array.from(Array(10), (x, i) => `{{${i + 1}}}`);
const maxTextLength = 1024;
const maxFooterLength = 60;

function checkValidName(input: string): boolean {
    const validRegex = /^[a-z0-9_]+$/;
    return validRegex.test(input);
}

function fieldsToVarValue(fields: FieldData[]): Map<string, string> {
    const result: Map<string, string> = new Map();

    const allFieldsButtonIndex: number[] = fields
        .filter((f) => f.name.includes(addVariableListName) && typeof f.name[1] === "number")
        .map((f) => f.name[1]);
    const allIndexes = Array.from(new Set(allFieldsButtonIndex));

    for (const index of allIndexes) {
        const fieldsForIndex = fields.filter(
            (f) => f.name.includes(addVariableListName) && f.name.includes(index)
        );
        const key = fieldsForIndex.find((f) => f.name.includes("variable"))!.value as string;
        const example = fieldsForIndex.find((f) => f.name.includes("usage"))!.value as string;
        result.set(key, example || "");
    }

    return result;
}

function mimeTypeToTemplateType(mimeType?: string): TemplateType {
    if (!mimeType) return TemplateType.TEXT;
    else if (mimeType.includes("pdf")) return TemplateType.DOCUMENT;
    else if (mimeType.includes("image")) return TemplateType.IMAGE;
    return TemplateType.TEXT;
}

function getQuickReplyButtonsText(formValues?: any): Array<string> {
    if (!formValues) return [];
    return Object.keys(formValues)
        .filter((k) => k.includes("quickReply"))
        .map((k) => formValues[k]);
}

const logger = new Logger("dashboard/NewTemplateForm", api.api_url().get());

function NewTemplateForm({ templates, reloadCb, createTemplateCb }: NewTemplateFormProps) {
    const { t, i18n } = useTranslation();
    const [buttons, setButtons] = useState<
        Array<{
            id: string;
            type: buttonsType;
        }>
    >([]);
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<any>();
    const history = useHistory();
    //@ts-ignore
    const { state } = useContext(AppContext);

    const tt = useCallback(
        (key: string) => {
            return t(`outbound.templateObj.${key}`);
        },
        [t]
    );

    const unsubscribeText = useMemo(() => {
        const langForm = formValues?.language;
        let lang = "es";

        if (langForm === "ENGLISH") {
            lang = "en";
        } else if (!["SPANISH", undefined].includes(langForm)) {
            logger.error(
                `Language "${langForm}" not implemented in NewTemplateForm unsubscribeText`
            );
        }
        const messages:
            | undefined
            | { en: { [key: string]: string }; es: { [key: string]: string } } =
            state?.workspaceConfigs?.messages;

        // @ts-ignore
        const messagesByLang = messages ? messages[lang] : undefined;
        const message: undefined | string = messagesByLang?.unsubscribe;

        if (message) {
            return message.slice(0, maxFooterLength);
        }

        const localT = i18n.getFixedT(lang);
        const text = localT("outbound.templateObj.unsubscribeText");
        return text.slice(0, maxFooterLength);
    }, [formValues?.language, i18n, state?.workspaceConfigs?.messages]);

    const quickReplyButtonsText: Array<string> = useMemo(() => {
        return getQuickReplyButtonsText(formValues);
    }, [formValues]);

    const [customerFields, customerFieldsLoading, customerFieldsError, reloadCustomerFields] =
        useCustomerFields({ api });

    const handleOnFieldsChangeSyncFields = (changedFields: FieldData[], allFields: FieldData[]) => {
        if (!changedFields.length) return;
        const changedField = changedFields[0];

        if (
            changedField.name.includes(addVariableListName) &&
            changedField.name.includes("variable")
        ) {
            const newTemplateBody = form.getFieldValue(templateBodyName) || "";
            form.setFields([
                { name: templateBodyName, value: newTemplateBody + changedField.value },
            ]);
        }

        if (
            (changedField.name.includes(addVariableListName) &&
                changedField.name.includes("usage")) ||
            changedField.name.includes(templateBodyName)
        ) {
            const templateBody = form.getFieldValue(templateBodyName) || "";
            let templateExample: string = templateBody;
            const varValues = fieldsToVarValue(allFields);
            for (const [k, v] of Array.from(varValues)) {
                templateExample = templateExample.replaceAll(k, v);
            }
            form.setFields([{ name: templateExampleName, value: templateExample }]);
        }

        const formValues = form.getFieldsValue();
        setFormValues((values: any) => {
            return { ...values, ...formValues };
        });
    };

    const constructFileDataBody = async (
        file: any
    ): Promise<{ name: string; mimeType: string; dataB64: string }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result as string;
                const rawData = base64String?.split(",")[1];
                resolve({
                    name: file.name,
                    mimeType: file.type,
                    dataB64: rawData,
                });
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    };

    const handleFormFinish = async (values: any) => {
        const buttons: TemplateButton[] = [];
        const quickReplyButtonsText = getQuickReplyButtonsText(values);

        if (values.phoneNumber) {
            buttons.push({
                type: "PHONE_NUMBER",
                text: values.phoneNumberButtonText,
                phoneNumber: values.phoneNumber,
            });
        }

        if (quickReplyButtonsText) {
            quickReplyButtonsText.forEach((text) =>
                buttons.push({
                    type: "QUICK_REPLY",
                    text: text,
                })
            );
        }

        if (values.urlButton) {
            buttons.push({
                type: "URL",
                text: values.urlButtonText,
                url: values.urlButton,
                urlExample: values.urlButtonExample,
            });
        }

        let fileData = undefined;
        if (values.uploadFile) {
            fileData = await constructFileDataBody(values.uploadFile.file);
        }
        message.loading(tt("uploadingTemplateMessage"), 10);

        const footerText =
            values[addUnsubscribeTextCheckbox] === false ? undefined : unsubscribeText;
        try {
            const error = await createTemplateCb({
                name: values.name,
                key: values.name,
                category: TemplateCategory.UTILITY, // Backend may change this category when uploading to gupshup
                language: values.language,
                text: values.templateBody,
                example: values.templateBodyExample,
                buttons: buttons?.length ? buttons : undefined,
                fileData: fileData,
                templateType: mimeTypeToTemplateType(fileData?.mimeType),
                footer: footerText,
            });
            message.destroy();
            if (error) {
                message.error(t("unknownError"));
            } else {
                message.success(tt("templateCreatedMessage"));
                history.goBack();
            }
        } catch {
            message.destroy();
            message.error(t("unknownError"));
        }
    };

    const handleBeforeUploadImage = (file: RcFile) => {
        const formValues = { ...form.getFieldsValue(), file: file };
        setFormValues(formValues);
        return false;
    };

    const handleOnChangeUpload = (event: any) => {
        if (!event?.file) return;
        const file = event.file;

        const fileWasRemoved = file.status === "removed";
        if (fileWasRemoved) {
            const newValues = { ...formValues };
            delete newValues.file;
            setFormValues(newValues);
        }
    };

    const removeButton = (buttonId: string) => {
        setButtons((btns) => btns.filter((b) => b.id !== buttonId));
        const newFormValues = { ...formValues };
        if (buttonId.includes("url")) {
            delete newFormValues.urlButton;
            delete newFormValues.urlButtonExample;
            delete newFormValues.urlButtonText;
            form.setFields([
                { name: urlButtonName, value: undefined },
                { name: urlButtonExampleName, value: undefined },
                { name: urlButtonText, value: undefined },
            ]);
        } else if (buttonId.includes("phone")) {
            delete newFormValues.phoneNumber;
            delete newFormValues.phoneNumberButtonText;
            form.setFields([
                { name: phoneNumber, value: undefined },
                { name: phoneNumberButtonText, value: undefined },
            ]);
        } else if (buttonId.includes("quickReply")) {
            delete newFormValues[buttonId];
            form.setFields([{ name: quickReplyButton, value: undefined }]);
        }

        setFormValues(newFormValues);
    };

    function camelToSnake(camelCaseString: string): string {
        return camelCaseString.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
    }

    const renderTemplateVariables = () => {
        return (
            <>
                {customerFields?.map((field) => {
                    const key = camelToSnake(field.key);
                    const fieldName = field.isNative
                        ? `{{customer.${key}}}`
                        : `{{customer.extra_data.${key}}}`;
                    return <Select.Option value={fieldName}>{fieldName}</Select.Option>;
                })}
                <Select.Option value="{{workspace.name}}">{`{{workspace.name}}`}</Select.Option>
                {templateNumericVariables.map((element) => {
                    const numero = element;
                    return <Select.Option value={numero}>{numero}</Select.Option>;
                })}
            </>
        );
    };

    const handleOnSelectEmoji = (e: Emoji) => {
        let formValues = form.getFieldsValue();
        const text = `${formValues[templateBodyName] || ""}${e.native}`;
        const example = `${formValues[templateExampleName] || ""}${e.native}`;

        form.setFields([
            { name: templateBodyName, value: text },
            { name: templateExampleName, value: example },
        ]);

        // This is not the best solution beacuse react does not guarantee read-your-writes consistency,
        // but it works well enough for now
        formValues = form.getFieldsValue();

        setFormValues((values: any) => {
            return { ...values, ...formValues };
        });
    };

    const requiredRule = [{ required: true, message: t("required") }];
    const templateTextRule = [
        { 
            required: true, message: t("required") 
        },
        {
            validator: (_: any, value: string) => {
                if (value && /\n{3,}/.test(value)) {
                    return Promise.reject(new Error(tt("templateTextLineBreakLimit")));
                }
                return Promise.resolve();
            }
        }
    ]

    return (
        <Row>
            <Col span={12}>
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark
                    onFieldsChange={handleOnFieldsChangeSyncFields}
                    onFinish={handleFormFinish}
                >
                    <Form.Item
                        name="name"
                        label={
                            <>
                                {tt("name")}
                                <TitleTooltip content={tt("requiredByWhatsApp")} />
                            </>
                        }
                        rules={[
                            ...requiredRule,
                            () => ({
                                validator(_, value) {
                                    if (!checkValidName(value))
                                        return Promise.reject(new Error(tt("nameValidationError")));
                                    const nameAlreadyExists =
                                        templates?.filter((t) => t.name === value).length > 0;
                                    if (nameAlreadyExists)
                                        return Promise.reject(new Error(tt("nameInUse")));
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label={tt("language")} name="language" rules={requiredRule}>
                        <Select placeholder={tt("selectLanguage")}>
                            <Option value={Language.ENGLISH}>{t("english")}</Option>
                            <Option value={Language.SPANISH}>{t("spanish")}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={
                            <div>
                                <div style={{ fontSize: "1rem", marginTop: "1rem" }}>
                                    {tt("body")}
                                </div>
                                <Text type="secondary">
                                    <div
                                        className="formatted-content"
                                        style={{ fontSize: "0.90rem" }}
                                        dangerouslySetInnerHTML={{
                                            __html: tt("templateFormattingHelp"),
                                        }}
                                    />
                                </Text>
                            </div>
                        }
                        name={templateBodyName}
                        rules={templateTextRule}
                    >
                        <TextArea rows={4} maxLength={maxTextLength} />
                    </Form.Item>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "-1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <CustomEmojiPicker onEmojiSelect={handleOnSelectEmoji} />
                    </span>

                    <Form.List name={addVariableListName}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: "flex" }} align="baseline">
                                        <Form.Item {...restField} name={[name, "variable"]}>
                                            <SelectOnce
                                                style={{ minWidth: "15rem" }}
                                                loading={customerFieldsLoading}
                                            >
                                                {renderTemplateVariables()}
                                            </SelectOnce>
                                        </Form.Item>
                                        <Form.Item name={[name, "usage"]} rules={requiredRule}>
                                            <Input placeholder={tt("variableExamplePlaceholder")} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block>
                                        {" "}
                                        {tt("addVariableToTemplate")}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Form.Item label={tt("unsubscribe")}>
                        <Text style={{ fontSize: ".9rem" }} type="secondary">
                            {tt("unsubscribeTextHelp")}
                        </Text>
                        <br />
                        <FormItem name={addUnsubscribeTextCheckbox} noStyle>
                            <Switch defaultChecked />
                        </FormItem>
                        <span style={{ fontSize: "1rem", marginLeft: "1rem" }}>
                            {tt("addUnsubscribeMessage")}
                        </span>
                    </Form.Item>

                    {/* Example Form.Item is hidden but is used for template preview */}
                    <Form.Item
                        label={tt("templateExampleLabel")}
                        name={templateExampleName}
                        rules={requiredRule}
                        hidden={true}
                    >
                        <TextArea placeholder={tt("templateExamplePlaceholder")} rows={4} />
                    </Form.Item>

                    <Form.Item label={tt("imageLabel")} name="uploadFile">
                        {/* @ts-ignore */}
                        <Upload
                            listType="picture"
                            beforeUpload={handleBeforeUploadImage}
                            onChange={handleOnChangeUpload}
                            accept={acceptedFileTypes}
                        >
                            {!formValues?.file && (
                                <Button
                                    className="opt-button opt-button--light"
                                    icon={<UploadOutlined />}
                                >
                                    {tt("imageLabel")}
                                </Button>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item label={tt("addButtonLabel")} name={"addButtonName"}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                className="opt-button opt-button--light"
                                onClick={() =>
                                    setButtons((v) => [
                                        ...v,
                                        { id: `quickReply${Math.random()}`, type: "QUICK_REPLY" },
                                    ])
                                }
                                disabled={
                                    buttons.filter((b) => b.type === "QUICK_REPLY").length > 2
                                }
                            >
                                {tt("quickReply")}
                            </Button>
                            <div style={{ width: "3rem" }} />
                            <Button
                                className="opt-button opt-button--light"
                                onClick={() =>
                                    setButtons((v) => [
                                        ...v,
                                        { id: `phone${Math.random()}`, type: "PHONE" },
                                    ])
                                }
                                disabled={buttons.filter((b) => b.type === "PHONE").length > 0}
                            >
                                {tt("callToPhone")}
                            </Button>
                            <div style={{ width: "3rem" }} />
                            <Button
                                className="opt-button opt-button--light"
                                onClick={() =>
                                    setButtons((v) => [
                                        ...v,
                                        { id: `url${Math.random()}`, type: "URL" },
                                    ])
                                }
                                disabled={buttons.filter((b) => b.type === "URL").length > 0}
                            >
                                {tt("url")}
                            </Button>
                        </div>
                    </Form.Item>

                    {buttons.map((btn) => {
                        if (btn.type === "QUICK_REPLY") {
                            return (
                                <Card
                                    type="inner"
                                    title={tt(`quickReplyTitle`)}
                                    extra={
                                        <CloseCircleOutlined onClick={() => removeButton(btn.id)} />
                                    }
                                    id={`card${btn.id}`}
                                >
                                    <Form.Item
                                        label={tt("quickReplyButtonLabel")}
                                        name={`${btn.id}`}
                                        rules={requiredRule}
                                    >
                                        <Input
                                            placeholder={tt("quickReplyButtonPlaceholder")}
                                            maxLength={20}
                                        />
                                    </Form.Item>
                                </Card>
                            );
                        } else if (btn.type === "PHONE") {
                            return (
                                <Card
                                    type="inner"
                                    title={tt("callPhoneTittle")}
                                    extra={
                                        <CloseCircleOutlined onClick={() => removeButton(btn.id)} />
                                    }
                                    id={`card${btn.id}`}
                                >
                                    <Form.Item
                                        label={tt("callPhoneLabel")}
                                        rules={[
                                            ...requiredRule,
                                            () => ({
                                                validator(_, value) {
                                                    const validRegex = /^[0-9]+$/;
                                                    if (validRegex.test(value)) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject(
                                                            new Error(tt("invalidNumber"))
                                                        );
                                                    }
                                                },
                                            }),
                                        ]}
                                        name={phoneNumber}
                                    >
                                        <Input placeholder={tt("callPhonePlaceholder")} />
                                    </Form.Item>
                                    <Form.Item
                                        label={tt("callPhoneButtonLabel")}
                                        name={phoneNumberButtonText}
                                        rules={requiredRule}
                                    >
                                        <Input
                                            placeholder={tt("callPhoneButtonText")}
                                            maxLength={20}
                                        />
                                    </Form.Item>
                                </Card>
                            );
                        } else if (btn.type === "URL") {
                            return (
                                <Card
                                    type="inner"
                                    title={tt("goToUrlTittle")}
                                    extra={
                                        <CloseCircleOutlined onClick={() => removeButton(btn.id)} />
                                    }
                                    id={`card${btn.id}`}
                                >
                                    <Form.Item
                                        rules={[
                                            ...requiredRule,
                                            { type: "url", message: tt("invalidUrl") },
                                        ]}
                                        label={tt("goToUrlLabel")}
                                        name={urlButtonName}
                                    >
                                        <Input placeholder={tt("goToUrlPlaceholder")} />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            ...requiredRule,
                                            { type: "url", message: tt("invalidUrl") },
                                        ]}
                                        label={tt("exampleUrlLabel")}
                                        name={urlButtonExampleName}
                                    >
                                        <Input placeholder={tt("exampleUrlPlaceholder")} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label={tt("textOfTheUrlButton")}
                                        name={urlButtonText}
                                    >
                                        <Input
                                            placeholder={tt("textOfTheUrlButtonPlaceholder")}
                                            maxLength={20}
                                        />
                                    </Form.Item>
                                </Card>
                            );
                        }
                        return <></>;
                    })}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="opt-button">
                            {tt("mainSubmitButtonText")}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={12} style={{ paddingLeft: "5rem" }}>
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                        overflowY: "auto",
                    }}
                >
                    <h2>{tt("preview")}</h2>
                    <small>{tt("previewInfo")}</small>
                    <WhatsappMessagePreview
                        values={{
                            ...formValues,
                            quickReplyButtons: quickReplyButtonsText,
                            footer:
                                formValues && formValues[addUnsubscribeTextCheckbox] === false
                                    ? undefined
                                    : unsubscribeText,
                        }}
                    />
                </div>
            </Col>
        </Row>
    );
}
export default NewTemplateForm;
